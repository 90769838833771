import React, { useEffect, useState } from 'react';

export type ParserReviver = Parameters<JSON['parse']>[1];

/**
 * Sync state to local storage so that it persists through a page refresh
 *
 * TODO: extract hook to react-hook-recipes repository
 * @param key localStorage key
 * @param initialValue any serializable value
 * @param parserReviver JSON.parser reviver method, prescribes how each value originally produced by parsing is transformed before being returned
 * @returns stateful value, and a function to update it
 * @see https://usehooks.com/useLocalStorage/
 */
export const useLocalStorage = <T>(key: string, initialValue: T, parserReviver?: ParserReviver) => {
  // Make a type guard to check if the parsed object is of type T
  const isOfTypeT = <T>(obj: any): obj is T => {
    for (const key in obj as T) {
      if (!(key in obj)) {
        return false;
      }
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        if (!isOfTypeT(obj[key])) {
          return false;
        }
      }
    }
    return true;
  };

  //undefined is not a valid JSON token, so make sure not store it
  const setValueToLocalStorage = (value: T) => {
    if (value === undefined) return window.localStorage.removeItem(key);
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  useEffect(() => {
    const storageChangeHandler = () => {
      const newValue = window.localStorage.getItem(key);
      if (newValue) {
        try {
          const parsedValue = JSON.parse(newValue, parserReviver);
          if (isOfTypeT<T>(parsedValue)) {
            setStoredValue(parsedValue);
          }
        } catch (error) {
          console.error('Error parsing stored value:', error);
        }
      }
    };

    window.addEventListener('storage', storageChangeHandler);

    // Cleanup function to remove event listener on unmount
    return () => window.removeEventListener('storage', storageChangeHandler);
  }, [key]); // Dependency on key to ensure listener updates

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue

      if (item) {
        const parsedItem = JSON.parse(item, parserReviver);
        if (isOfTypeT<T>(parsedItem)) return parsedItem;
      }

      setValueToLocalStorage(initialValue);
      return initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);

      // Save to local storage
      if (typeof window !== 'undefined') {
        setValueToLocalStorage(valueToStore);
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue] as const;
};
