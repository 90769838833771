import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { useResizeObserver } from './useResizeObserver';
import { elementArea, widthToBreakpoint, Breakpoint, ElementArea } from './theme/breakpoints';
import { getInstanceId } from './getInstanceId';

export interface ContainersArea {
  widgetArea: ElementArea;
  viewportArea: ElementArea;
  proportionalWidgetArea: ElementArea;
}

export interface WidgetContext extends ContainersArea {
  element: HTMLElement;
  stylesContainer: HTMLElement;
  containerElement: HTMLElement;
  /** It is used to know if the widget is directly inside of a shadow dom */
  hasShadowDom: boolean;
  /** Sequentially generated number based on the amount of widgets in the page
   * interestingly for differentiate to which widget should global params, such querystring
   * for instance, be applied
   *
   * @remarks it considers any known widget (catalog, product and booking)
   */
  instanceId: number;
  /** SizeFactor will affect breakpoints since sometimes a configuration (e.g. font size)
   * will expand a widget in a way that layout doesn't fit anymore in the breakpoint.
   *
   * @remarks This function allows to change widget width information proportionally to the
   * sizeFactor so breakpoints will answer with propotional widget size (small, medium, large).
   */
  setSizeFactor: Dispatch<SetStateAction<number>>;
}

export interface WidgetContextProviderProps {
  element: HTMLElement;
  containerElement: HTMLElement;
  viewportElement: HTMLElement;
  stylesContainer: HTMLElement;
  hasShadowDom: boolean;
}

export const defaultWidgetContextValue = {
  element: document.body,
  widgetArea: elementArea(Breakpoint.Small),
  viewportArea: elementArea(widthToBreakpoint(window.innerWidth)),
  proportionalWidgetArea: elementArea(Breakpoint.Small),
  stylesContainer: document.head,
  containerElement: document.body,
  hasShadowDom: true,
  instanceId: 0,
  setSizeFactor: () => {},
};
export const widgetContext = createContext<WidgetContext>(defaultWidgetContextValue);

export const WidgetContextProvider = ({
  children,
  element,
  containerElement,
  viewportElement,
  stylesContainer,
  hasShadowDom,
}: PropsWithChildren<WidgetContextProviderProps>) => {
  const [sizeFactor, setSizeFactor] = useState(1);
  const { viewportArea, widgetArea, proportionalWidgetArea } = useResizeObserver(
    containerElement,
    viewportElement,
    sizeFactor
  );
  const instanceId = getInstanceId(containerElement, hasShadowDom);

  return (
    <widgetContext.Provider
      value={{
        element,
        widgetArea: elementArea(widgetArea),
        viewportArea: elementArea(viewportArea),
        proportionalWidgetArea: elementArea(proportionalWidgetArea!),
        setSizeFactor,
        stylesContainer,
        containerElement,
        hasShadowDom,
        instanceId,
      }}
    >
      {children}
    </widgetContext.Provider>
  );
};

export const useWidget = () => useContext(widgetContext);
