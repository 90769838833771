export const details = {
  prefix: 'Gw-p',
  root: 'guest-product-details',
  drawer: {
    root: 'guest-drawer',
    header: 'guest-drawer-header',
    content: 'guest-drawer-content',
  },
  collapsible: {
    root: 'guest-collapsible',
    header: 'guest-collapsible-header',
    content: {
      root: 'guest-collapsible-content',
      closed: 'guest-collapsible-content-closed',
      open: 'guest-collapsible-content-open',
    },
  },
  backLink: {
    mobile: 'guest-back-link-mobile',
    desktop: 'guest-back-link-desktop',
  },
  content: {
    root: 'guest-content',
    main: 'guest-content-main',
    side: 'guest-content-side',
  },
  reviews: {
    root: 'guest-reviews',
    header: 'guest-reviews-header',
    preview: 'guest-reviews-preview',
    summary: 'guest-reviews-summary',
    loadButtonContainer: 'guest-reviews-load-button-container',
    list: 'guest-reviews-list',
    carousel: 'guest-reviews-carousel',
    review: {
      root: 'guest-review',
      header: 'guest-review-header',
      body: 'guest-review-body',
      caption: 'guest-review-caption',
      replies: 'guest-review-replies',
    },
    rating: {
      stars: 'guest-reviews-rating',
      category: {
        root: 'guest-reviews-rating-category',
        label: 'guest-reviews-rating-category-label',
      },
    },
  },
  crossSelling: {
    root: 'guest-cross-selling',
    header: 'guest-cross-selling-header',
    list: 'guest-cross-selling-list',
    item: 'guest-cross-selling-product',
  },
  booking: {
    root: 'guest-booking-container',
    header: 'guest-booking-header',
    price: 'guest-booking-price',
    priceLabel: 'guest-booking-price-label',
    priceValue: 'guest-booking-price-value',
    mobileButton: 'guest-booking-mobile-button',
    drawer: 'guest-booking-drawer',
  },
  tripadvisor: 'guest-tripadvisor',
  showDescription: 'guest-product-short-description',
  highlights: 'guest-product-highlights',
  description: {
    root: 'guest-product-description',
    body: 'guest-product-description-body',
  },
  information: {
    root: 'guest-product-information',
    product: {
      root: 'guest-product-information-item',
      icon: 'guest-product-information-item-icon',
      header: 'guest-product-information-item-header',
      body: 'guest-product-information-item-body',
    },
    covid19: 'guest-product-information-item-covid19',
    services: 'guest-product-information-item-services',
    notIncluded: 'guest-product-information-item-not-included',
    duration: 'guest-product-information-item-duration',
    equipment: 'guest-product-information-item-equipment',
    participants: 'guest-product-information-item-participants',
    languages: 'guest-product-information-item-languages',
    openingHours: 'guest-product-information-item-opening-hours',
    importantInfo: 'guest-product-information-item-important-info',
    attachments: 'guest-product-information-item-attachments',
    requirements: 'guest-product-information-item-requirements',
  },
  location: {
    root: 'guest-product-location',
    description: 'guest-product-location-description',
    details: {
      root: 'guest-product-location-details',
      address: 'guest-product-location-address',
      operator: 'guest-product-location-operator',
      name: 'guest-product-location-name',
    },
    additional: {
      root: 'guest-product-location-additional',
      parking: 'guest-product-location-parking',
      publicTransport: 'guest-product-location-public-transport',
    },
    map: {
      image: 'guest-product-location-map-image',
      interactive: 'guest-product-location-map-interactive',
    },
  },
  header: 'guest-product-header',
  headerLabels: 'guest-product-header-labels',
  tags: 'guest-product-tags',
  labels: {
    rating: 'guest-product-label-rating',
    covid19: 'guest-product-label-covid19',
    duration: 'guest-product-label-duration',
    location: 'guest-product-label-location',
    soldOut: 'guest-product-label-sold-out',
    saleEnded: 'guest-product-label-sold-sale-ended',
    new: 'guest-product-label-new',
    bestseller: 'guest-product-label-bestseller',
  },
  gallery: {
    root: 'guest-product-gallery',
    thumbnails: {
      root: 'guest-product-thumbnails',
      previousButton: 'guest-product-thumbnails-previous',
      nextButton: 'guest-product-thumbnails-next',
      pages: 'guest-product-thumbnails-pages',
      page: 'guest-product-thumbnails-page',
      thumbnail: 'guest-product-thumbnail',
    },
    screen: {
      root: 'guest-product-gallery-screen',
      imageSlide: 'guest-product-gallery-slide guest-product-image',
      videoSlide: 'guest-product-gallery-slide guest-product-video',
      previousButton: 'guest-product-gallery-previous',
      nextButton: 'guest-product-gallery-next',
      video: {
        frame: 'guest-product-video-frame',
        preview: 'guest-product-video-preview',
        playButton: 'guest-product-video-play',
      },
    },
  },
};
