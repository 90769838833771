import { Typography } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import React, { Suspense } from 'react';
import { Text } from '@eo-locale/react';

export const InvalidContent = () => {
  const { palette } = useTheme();

  const Icon = React.lazy(
    () => import('@guest-widgets/shared/src/components/Icons/ErrorNotFoundIcon')
  );

  return (
    <Container>
      <Suspense fallback={null}>
        <Icon color={palette.secondary.main} />
      </Suspense>
      <Typography variant="h2">
        <Text id="invalid-content.title" />
      </Typography>
      <Typography variant="body1">
        <Text id="invalid-content.desc" />
      </Typography>
    </Container>
  );
};

const Container = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: spacing(10),
  gap: spacing(10),
  alignItems: 'center',
  '& *': {
    textAlign: 'center',
  },
}));
