import { useTheme } from '@mui/material/styles';
import { Theme } from '@mui/system';
import { SxProps } from '@mui/material';
import { shadeColor } from '@guest-widgets/shared/src/utils/colors';

import { useSettings } from '../contexts/settingsContext/settingsContext';
import { useModalState } from '../contexts/modalContext/modalContext';

export const useConversionBoosterStyles = (): SxProps<Theme> => {
  const { isConversionBooster, floating } = useSettings();
  const { isOpen } = useModalState();

  const {
    palette: {
      primary: { main },
      getContrastText,
    },
  } = useTheme();

  if (!isConversionBooster || !floating) return {};

  const animation: { [key: string]: { top: 0 | '-10px' } } = {};
  for (let percent = 50; percent <= 100; percent++) {
    animation[`${percent}%`] = {
      top: percent % 2 === 0 ? 0 : '-10px',
    };
  }

  return {
    '@keyframes idle-animation': {
      '0%': {
        top: 0,
      },
      ...animation,
    },
    borderRadius: 0,
    transition: 'all 0.3s linear',
    boxShadow: `4px 5px ${shadeColor(main, -40)}`,
    transformOrigin: 'center',
    transform: 'skewX(-30deg)',
    backgroundColor: main,
    color: getContrastText(main),
    ':hover': {
      boxShadow: '4px 5px black',
      padding: '8px 48px',
      backgroundColor: main,
    },
    'span:not(.MuiTouchRipple-root)': {
      transform: 'skewX(30deg)',
    },
    '&:not(:hover)': {
      padding: '8px 32px',
      animation: isOpen ? '' : 'idle-animation 10s ease infinite',
    },
  };
};
