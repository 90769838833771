import {
  Messages,
  SourceLanguageMap,
  SourceLanguageLocaleCode,
  SourceLanguageCode,
} from './languageLocaleCodes';
import englishMessages from './locales/en_US.json';
import { localesMapper } from './localesMapper';

/**
 * Converts a locale string from the 'en_US' format to the 'en-US' format.
 * This conversion ensures compatibility with the BCP 47 standard,
 * which is required by the Intl APIs and commonly used by i18n.
 *
 * @param {string} locale - The locale string in 'language_COUNTRY' format (e.g., 'en_US').
 * @returns {string} - The locale string in 'language-COUNTRY' format (e.g., 'en-US').
 */
export const formatLocaleForI18n = (locale: string): string => locale.replace('_', '-');

/**It represents a list of dynamic imports to language files */
export type LanguageMap = SourceLanguageMap;
export type LanguageLocaleCode = SourceLanguageLocaleCode;
export type LanguageCode = SourceLanguageCode;

export type EoLocale = {
  language: LanguageLocaleCode;
  messages: Messages;
};

export const sharedMessages: LanguageMap = {
  'en-US': () => Promise.resolve(englishMessages),
  ...localesMapper,
};
