export enum Breakpoint {
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export type ElementArea = ReturnType<typeof elementArea>;

const breakpoints = {
  [Breakpoint.Small]: 600,
  [Breakpoint.Medium]: 960,
  [Breakpoint.Large]: Infinity,
};

export const widthToBreakpoint = (width: number) => {
  return (Object.entries(breakpoints).find(([, until]) => width < until)?.[0] ||
    Breakpoint.Large) as Breakpoint;
};

export const elementArea = (breakpoint: Breakpoint) => {
  function basedOnSize<T, T2 = T, T3 = T>(
    smallCase: T,
    mediumCase?: T2,
    largeCase?: T3
  ): T | T2 | T3 {
    if (breakpoint === Breakpoint.Small) {
      return smallCase;
    }

    if (breakpoint === Breakpoint.Medium) {
      return mediumCase ?? smallCase;
    }

    return largeCase ?? mediumCase ?? smallCase;
  }

  return {
    isSmall: breakpoint === Breakpoint.Small,
    isMedium: breakpoint === Breakpoint.Medium,
    isLarge: breakpoint === Breakpoint.Large,
    basedOnSize,
  };
};
