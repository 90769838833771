import { Settings, WidgetType } from './settings';

export const initialSettings: Settings = {
  target: '_top',
  widgetType: '' as WidgetType,
  size: 'medium',
  locale: 'en-US',
  customerCode: '',
  productId: '',
};

export const initialConfiguration = {
  fontSize: 1,
  fontFamily: '"Roboto", sans-serif',
  accentColor: '#1D2939',
};
