import React, { ComponentType } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import { copyCustomStyles } from './styles';
import { WidgetContextProvider } from './widgetContext';
import { hasDuplicatedScript, throwConsoleError } from './hasDuplicatedScript';

const toCamelCase = (str: string) => str.replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ''));

const attributesNames = {
  stylesTemplateId: 'styles-template-id',
  viewportId: 'viewport-id',
};

export interface WidgetWebComponent extends HTMLElement {
  render(): void;
}

export const register = (
  Component: ComponentType<any>,
  name: string,
  observedAttributes: string[] = [],
  hasShadowDom: (attributes: { [key: string]: string }) => boolean = () => true,
  customize?: (constructor: CustomElementConstructor) => void
) => {
  class WebComponent extends HTMLElement implements WidgetWebComponent {
    mountPoint!: HTMLElement;
    stylesContainer!: HTMLElement;
    customStylesPoint!: HTMLElement;

    connectedCallback() {
      if (!this.shadowRoot && hasShadowDom(this.mapAttributes())) {
        // Doing this here and not in the constructor because we want to base this on the attributes
        // which are null at the point of instantiating the element
        this.attachShadow({ mode: 'open' });
      }

      this.initDOM();

      copyCustomStyles(
        this.getAttribute(attributesNames.stylesTemplateId) || this,
        this.customStylesPoint
      );

      this.render();
    }

    disconnectedCallback() {
      // This is necessary to trigger the unmounting mechanism for all react hooks.
      unmountComponentAtNode(this.mountPoint);
    }

    attributeChangedCallback(attr: string, oldValue: string, newValue: string) {
      if (!this.isConnected || !this.mountPoint) return;

      if (attr === attributesNames.stylesTemplateId) {
        return copyCustomStyles(newValue || this, this.customStylesPoint);
      }

      this.render();
    }

    static get observedAttributes() {
      return observedAttributes;
    }

    private mapAttributes() {
      return observedAttributes.reduce(
        (attributes, attr) => ({
          ...attributes,
          [toCamelCase(attr)]: this.getAttribute(attr) || '',
        }),
        {}
      );
    }

    private initDOM() {
      const create = (id?: string) => {
        let item = document.createElement('div');
        if (id) item.setAttribute('id', id);
        return item;
      };

      const root = this.shadowRoot || this;

      this.mountPoint = create('root');
      this.stylesContainer = create('styles');
      this.customStylesPoint = create('custom-styles');

      root.append(this.stylesContainer, this.mountPoint);
      this.stylesContainer.append(this.customStylesPoint);
    }

    private getViewportElement() {
      const id = this.getAttribute(attributesNames.viewportId);
      return (id && document.getElementById(id)) || document.body;
    }

    public render() {
      const mappedAttributes = this.mapAttributes();

      render(
        <WidgetContextProvider
          hasShadowDom={hasShadowDom(mappedAttributes)}
          element={this}
          stylesContainer={this.stylesContainer}
          containerElement={this.mountPoint}
          viewportElement={this.getViewportElement()}
        >
          <Component {...mappedAttributes} />
        </WidgetContextProvider>,
        this.mountPoint
      );
    }
  }

  if (customize) customize(WebComponent);

  if (!customElements.get(name)) customElements.define(name, WebComponent);

  if (hasDuplicatedScript()) throwConsoleError();
};
