const packageName = import.meta.env.VITE_PACKAGE_NAME;

export const hasDuplicatedScript = () => {
  const localhost = window.location.hostname === 'localhost';
  const domainQuery = localhost ? '' : '[src*="regiondo.net"]';

  const scripts = document.body.querySelectorAll(
    `script${domainQuery}[src*="${packageName}.min.js"]`
  );

  return scripts.length > 1;
};

/** Console error with predefined message to use with hasDuplicatedScript function when it returns 'true' */
export const throwConsoleError = () => {
  console.error(
    `Your implementation of ${packageName?.toUpperCase()} has a problem. You have more than one tag script in your webpage:

    <script src=".../${packageName}.min.js" ></script>. 

  To avoid application malfunction, include this tag script just once.`
  );
};
