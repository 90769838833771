import React, { Suspense } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Text } from '@eo-locale/react';
import { styled } from '@guest-widgets/core';
import { useTheme } from '@mui/material/styles';

interface ErrorEmptyListProps {
  numberOfFiltersApplied?: number;
  onClick?(): void;
  type: ErrorState;
}

export enum ErrorState {
  //When Changed, also update the error types in locale JSON respectively
  Empty = 'empty',
  NotFound = 'not-found',
  UnableToLoad = 'unable-to-load',
}

export const ErrorViews = ({ numberOfFiltersApplied, onClick, type }: ErrorEmptyListProps) => {
  const { palette } = useTheme();
  const getErrorIcon = (state: ErrorState) =>
    ({
      [ErrorState.Empty]: React.lazy(() => import('./Icons/ErrorEmptyIcon')),
      [ErrorState.NotFound]: React.lazy(() => import('./Icons/ErrorNotFoundIcon')),
      [ErrorState.UnableToLoad]: React.lazy(() => import('./Icons/ErrorUnableToLoadIcon')),
    }[state]);

  const ErrorIcon = getErrorIcon(type);

  return (
    <Container>
      <ErrorIconContainer>
        <Suspense fallback={null}>
          <ErrorIcon color={palette.secondary.main} />
        </Suspense>
      </ErrorIconContainer>
      <Title variant="h2">
        <Text id={`errors.${type}.title`} />
      </Title>
      <Typography variant="body1">
        <Text id={`errors.${type}.description`} />
      </Typography>
      <Typography variant="body1">
        <Text id={`errors.${type}.options.title`} />
      </Typography>
      {type === ErrorState.Empty && (
        <OptionList content="ul" variant="body2">
          <li>
            <Text id={`errors.${type}.options.one`} />
          </li>
          <li>
            <Text id={`errors.${type}.options.two`} />
          </li>
          <li>
            <Text id={`errors.${type}.options.three`} />
          </li>
        </OptionList>
      )}
      {!(type === ErrorState.Empty && numberOfFiltersApplied === 0) && (
        <div>
          <Button variant="outlined" onClick={onClick}>
            <Text id={`errors.${type}.button`} />
          </Button>
        </div>
      )}
    </Container>
  );
};

const Title = styled(Typography)(({ theme: { typography }, widgetArea }) => ({
  fontSize: widgetArea.basedOnSize(
    typography.h4.fontSize,
    typography.h4.fontSize,
    typography.h2.fontSize
  ),
}));

const Container = styled('div')(({ theme }) => ({
  margin: 'auto',
  padding: theme.spacing(10),
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
}));

const OptionList = styled(Typography)({
  width: 'fit-content',
  margin: 'auto',
  textAlign: 'left',
});

const ErrorIconContainer = styled('div')({
  '& > svg': {
    width: '100%',
  },
});
