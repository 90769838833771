import { LanguageMapList } from '@guest-widgets/shared/src/i18n/languageLocaleCodes';

export const localesMapper: LanguageMapList = {
  'de-DE': async () => (await import('./locales/de_DE.json')).default,
  'fr-FR': async () => (await import('./locales/fr_FR.json')).default,
  'it-IT': async () => (await import('./locales/it_IT.json')).default,
  'es-ES': async () => (await import('./locales/es_ES.json')).default,
  'pt-PT': async () => (await import('./locales/pt_PT.json')).default,
  'nl-NL': async () => (await import('./locales/nl_NL.json')).default,
  'hu-HU': async () => (await import('./locales/hu_HU.json')).default,
  'zh-CN': async () => (await import('./locales/zh_CN.json')).default,
  'ja-JP': async () => (await import('./locales/ja_JP.json')).default,
  'sl-SI': async () => (await import('./locales/sl_SI.json')).default,
  'el-GR': async () => (await import('./locales/el_GR.json')).default,
  'hr-HR': async () => (await import('./locales/hr_HR.json')).default,
  'ca-ES': async () => (await import('./locales/ca_ES.json')).default,
  'ar-AE': async () => (await import('./locales/ar_AE.json')).default,
  'bg-BG': async () => (await import('./locales/bg_BG.json')).default,
  'cs-CZ': async () => (await import('./locales/cs_CZ.json')).default,
  'da-DK': async () => (await import('./locales/da_DK.json')).default,
  'de-AT': async () => (await import('./locales/de_AT.json')).default,
  'de-CH': async () => (await import('./locales/de_CH.json')).default,
  'de-LI': async () => (await import('./locales/de_LI.json')).default,
  'de-LU': async () => (await import('./locales/de_LU.json')).default,
  'el-CY': async () => (await import('./locales/el_CY.json')).default,
  'en-AU': async () => (await import('./locales/en_AU.json')).default,
  'en-CA': async () => (await import('./locales/en_CA.json')).default,
  'en-GB': async () => (await import('./locales/en_GB.json')).default,
  'en-IE': async () => (await import('./locales/en_IE.json')).default,
  'en-MT': async () => (await import('./locales/en_MT.json')).default,
  'en-NZ': async () => (await import('./locales/en_NZ.json')).default,
  'en-ZA': async () => (await import('./locales/en_ZA.json')).default,
  'es-CR': async () => (await import('./locales/es_CR.json')).default,
  'es-MX': async () => (await import('./locales/es_MX.json')).default,
  'es-PR': async () => (await import('./locales/es_PR.json')).default,
  'et-EE': async () => (await import('./locales/et_EE.json')).default,
  'fi-FI': async () => (await import('./locales/fi_FI.json')).default,
  'fil-PH': async () => (await import('./locales/fil_PH.json')).default,
  'fr-BE': async () => (await import('./locales/fr_BE.json')).default,
  'fr-CA': async () => (await import('./locales/fr_CA.json')).default,
  'fr-CH': async () => (await import('./locales/fr_CH.json')).default,
  'fr-LU': async () => (await import('./locales/fr_LU.json')).default,
  'he-IL': async () => (await import('./locales/he_IL.json')).default,
  'it-CH': async () => (await import('./locales/it_CH.json')).default,
  'lt-LT': async () => (await import('./locales/lt_LT.json')).default,
  'lv-LV': async () => (await import('./locales/lv_LV.json')).default,
  'ms-MY': async () => (await import('./locales/ms_MY.json')).default,
  'mt-MT': async () => (await import('./locales/mt_MT.json')).default,
  'nl-BE': async () => (await import('./locales/nl_BE.json')).default,
  'nl-LU': async () => (await import('./locales/nl_LU.json')).default,
  'no-NO': async () => (await import('./locales/no_NO.json')).default,
  'pl-PL': async () => (await import('./locales/pl_PL.json')).default,
  'ro-RO': async () => (await import('./locales/ro_RO.json')).default,
  'sk-SK': async () => (await import('./locales/sk_SK.json')).default,
  'sv-SE': async () => (await import('./locales/sv_SE.json')).default,
  'tr-TR': async () => (await import('./locales/tr_TR.json')).default,
  'zh-Hans-HK': async () => (await import('./locales/zh_Hans_HK.json')).default,
  'zh-Hant-HK': async () => (await import('./locales/zh_Hant_HK.json')).default,
};
