import React, { PropsWithChildren } from 'react';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import type { ConfigurationSettingsDto } from '@guest-widgets/shared/src/types/__mocks__/ConfigurationSettingsDto';

import { downloadFont } from './downloadFont';
import { customTheme } from './customTheme';

interface ConfigurationThemeProps {
  configuration?: ConfigurationSettingsDto;
  disableDownloadFont?: boolean;
}

export const ConfigurationTheme = ({
  children,
  configuration,
  disableDownloadFont,
}: PropsWithChildren<ConfigurationThemeProps>) => {
  const theme = useTheme();

  if (configuration?.fontFamily && !disableDownloadFont) {
    downloadFont(configuration.fontFamily);
  } else {
    downloadFont('Roboto');
  }

  return configuration ? (
    <ThemeProvider theme={customTheme(theme, configuration)}>
      <ScopedCssBaseline>{children}</ScopedCssBaseline>
    </ThemeProvider>
  ) : (
    <>{children}</>
  );
};
