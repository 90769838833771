import React, { PropsWithChildren, createContext, useMemo } from 'react';

import { useModalActivator } from '@guest-widgets/shared/src/hooks/useModalActivator';

const initialState = {
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
};

export interface ModalContext {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const modalContext = createContext<ModalContext>(initialState);

export const ModalProvider = ({ children }: PropsWithChildren<{}>) => {
  const { isOpen, onClose, onOpen } = useModalActivator();

  const value: ModalContext = {
    isOpen,
    onOpen,
    onClose,
  };

  return <modalContext.Provider value={value}>{children}</modalContext.Provider>;
};

export const useModalState = () => React.useContext(modalContext);
