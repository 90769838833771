import React, { PropsWithChildren, useMemo } from 'react';
import type { Theme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import createCache, { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { hasShadowDom as hasShadowDomResolver } from '@guest-widgets/shared/src/utils/hasShadowDom';

import { useWidget } from '../widgetContext';
import { IntegrationGuard } from '../IntegrationGuard';

import { createTheme } from './theme';
import { ThemeType } from './themeMapper';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

export interface MuiProviderProps {
  classNamePrefix: string;
  themeType: ThemeType;
}

export const MuiProvider = ({
  classNamePrefix,
  children,
  themeType,
}: PropsWithChildren<MuiProviderProps>) => {
  const { stylesContainer, containerElement } = useWidget();

  const hasShadowDom = hasShadowDomResolver(containerElement);

  const emotionCache = useMemo<EmotionCache>(() => {
    let insertionPoint: HTMLDivElement | undefined;

    if (hasShadowDom) {
      insertionPoint = document.createElement('div');
      insertionPoint.setAttribute('id', 'mui-insertion-point');
      stylesContainer.prepend(insertionPoint);
    }

    return createCache({
      key: classNamePrefix.toLowerCase(),
      prepend: true,
      container: stylesContainer,
    });
  }, []);

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={createTheme({ themeType, containerElement, hasShadowDom })}>
        <ScopedCssBaseline>
          <IntegrationGuard>{children}</IntegrationGuard>
        </ScopedCssBaseline>
      </ThemeProvider>
    </CacheProvider>
  );
};
