/**Public classes used by the DayPicker component. */
export const classes = {
  day: 'DayPicker-Day',
  selected: 'DayPicker-Day--selected',
  today: 'DayPicker-Day--today',
  dayElement: 'day',
  trafficLights: {
    available: 'DayPicker-Day--available',
    highDemand: 'DayPicker-Day--high-demand',
    soldOut: 'DayPicker-Day--sold-out',
  },
};
