export const shadeColor = (baseColor: string, shade: number): string => {
  const colors = [
    parseInt(baseColor.substring(1, 3), 16),
    parseInt(baseColor.substring(3, 5), 16),
    parseInt(baseColor.substring(5, 7), 16),
  ];

  let res = '#';

  colors.forEach((color: number) => {
    let newColor = Math.round((color * (100 + shade)) / 100);
    newColor = newColor < 255 ? newColor : 255;
    const sColor = newColor.toString(16);
    res += sColor.length == 1 ? '0' + sColor : sColor;
  });

  return res;
};
