import React from 'react';
import { Text } from '@eo-locale/react';
import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import Chevron from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { button } from '@guest-widgets/shared/src/classes/button';
import { useDisableScroll } from '@guest-widgets/shared/src/components/Drawer/useDisableScroll';

import { ContentWidget } from '../ContentWidget/ContentWidget';
import { useSettings } from '../contexts/settingsContext/settingsContext';
import { useModalState } from '../contexts/modalContext/modalContext';

type MobileDrawerProps = {
  onClose: () => void;
};
export const MobileDrawer = ({ onClose }: MobileDrawerProps) => {
  const { isOpen } = useModalState();

  const { widgetType: content } = useSettings();
  useDisableScroll(isOpen);

  return (
    <Drawer
      className={`${button.drawer.root} ${button.drawer.booking}`}
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      keepMounted={true}
      disableEnforceFocus
      PaperProps={{ sx: { height: '100%' } }}
    >
      <DrawerHeader className={button.drawer.header}>
        <IconButton data-cy="drawer-close-button" onClick={onClose} size="large">
          <Chevron />
        </IconButton>
        <Typography component="h2" variant="h3">
          <Text id={`content.title.${content?.toLowerCase()}`} />
        </Typography>
      </DrawerHeader>
      <div className={button.drawer.content}>
        <ContentWidget />
      </div>
    </Drawer>
  );
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 4, 1, 0),
  borderBottom: theme.border,
}));
