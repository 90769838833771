import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/system';

export interface LoadingButtonProps {
  loading: boolean;
  iconSize?: number;
  styles?: SxProps<Theme>;
}

/** Extension to material button adding a loading state
 * @remarks Remove it as soon as we have material-ui updated (already included in v5)
 */
export const LoadingButton = <C extends React.ElementType>({
  loading,
  iconSize = ICON_BASE_SIZE,
  styles,
  ...props
}: ButtonProps<C, { component?: C }> & LoadingButtonProps) => (
  <Button
    {...props}
    {...(loading
      ? {
          disabled: true,
          startIcon: <CircularProgress size={iconSize} color="inherit" />,
        }
      : {})}
  />
);

export const ICON_BASE_SIZE = 20;
