import _mergeWith from 'lodash.mergewith';

/** settings merged by priority order [higher to lower] */
export const objectsMerge = <T extends object>(...settings: Partial<T>[]) => {
  const mergedSettings = {} as T;
  _mergeWith(mergedSettings, ...settings, (vl: T) => {
    if (vl !== undefined && vl?.toString() !== '') return vl;
  });
  return mergedSettings;
};
