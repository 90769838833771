import { register } from '@guest-widgets/core';
import { widgetTagNames } from '@guest-widgets/shared/src/config';
import { checkLoadedAndRegister } from '@guest-widgets/shared/src/utils/singleLoad';

import { ButtonWidgetWebComponent } from './ButtonWidgetWebComponent';

(async () => {
  if (checkLoadedAndRegister('button')) return;

  register(
    ButtonWidgetWebComponent,
    widgetTagNames.button,
    [
      'customer-code',
      'product',
      'locale',
      'styles-template-id',
      'widget-id',
      'configuration',
      'sub-id',
      'target',
      'label',
      'widget-type',
      'size',
      'full-width',
      'floating-position',
      'url',
      'disable-download-font',
      'is-conversion-booster',
      'disable-features',
      'enable-features',
      'category-filter',
      'location-filter',
    ],
    () => true
  );
})();
