import { useQuery } from '@tanstack/react-query';
import { widgetEditorApi } from '@guest-widgets/shared/src/apis/widgetEditor';
import { LanguageLocaleCode } from '@guest-widgets/shared/src/i18n/i18n';

import type { Settings } from '../../settingsContext/settings';

const queryKey = 'settingsData';

type SettingsApiProps = Partial<
  Pick<Settings, 'widgetId' | 'locale' | 'customerCode' | 'productId' | 'isConversionBooster'>
>;

export const useSettingsApi = ({ widgetId, locale, customerCode, productId }: SettingsApiProps) => {
  const queryDeps = [queryKey, widgetId, locale, customerCode, productId];
  const hasParameters = Boolean(widgetId || (locale && customerCode && productId));

  const queryFn = (): Promise<Partial<Settings>> =>
    loadWidgetSettings({
      widgetId,
      locale,
      customerCode,
      productId,
    });

  return useQuery({ queryKey: queryDeps, queryFn, enabled: hasParameters });
};

/**It requests in case of existing widgetID widget-editor API, otherwise fallback to default passed values */
const loadWidgetSettings = async ({ widgetId, ...defaultValues }: SettingsApiProps) => {
  if (!widgetId) return defaultValues;

  const response = (
    await widgetEditorApi.getWidgetConfiguration({
      id: widgetId!,
    })
  ).data;

  return {
    ...response.settings,
    configuration: response.configuration,
    productId: response.settings.productId as string | undefined,
    locale: (response.settings.locale as unknown) as LanguageLocaleCode,
    customerCode: response.settings.companyCode,
  };
};
