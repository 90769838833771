import React, { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { objectsMerge } from '@guest-widgets/shared/src/utils/objectsMerge';
import { ConfigurationSettingsDto } from '@guest-widgets/shared/src/types/__mocks__/ConfigurationSettingsDto';
import { LanguageLocaleCode, formatLocaleForI18n } from '@guest-widgets/shared/src/i18n/i18n';

import { useSettingsApi } from '../apiContext/settings/useSettingsApi';

import { Settings, SettingsFromProps } from './settings';
import { initialConfiguration, initialSettings } from './initialSettings';

const settingsContext = createContext<SettingsContext>({
  ...initialSettings,
  loadingState: {} as LoadingState,
});

export type LoadingState = Omit<ReturnType<typeof useSettingsApi>, 'data'>;

export interface SettingsContext extends Settings {
  loadingState: LoadingState;
}

export const SettingsProvider = ({
  children,
  ...settingsFromProps
}: PropsWithChildren<SettingsFromProps>) => {
  const mergedSettings = objectsMerge<Settings>(settingsFromProps, initialSettings);
  const [settings, setSettings] = useState(mergedSettings);

  const { data: settingsFromBackend, ...loadingState } = useSettingsApi(mergedSettings);

  useEffect(() => {
    if (!settingsFromBackend) return;

    setSettings((prev) => ({ ...prev, ...settingsFromBackend }));
  }, [settingsFromBackend]);

  useEffect(() => {
    if (!settingsFromProps) return;

    setSettings((prev) => ({ ...prev, ...settingsFromProps }));
  }, [JSON.stringify(settingsFromProps)]);

  // Transparent background is applied to avoid white corners when button has a border radius
  const addPorpertyToConfig = (configuration: ConfigurationSettingsDto | undefined) => {
    if (!configuration) return { ...initialConfiguration, backgroundColor: 'transparent' };
    return { ...configuration, backgroundColor: 'transparent' };
  };

  let isError =
    loadingState.isError &&
    (!settings.locale || !settings.customerCode || !settings.productId || !settings.widgetType);

  const value: SettingsContext = {
    ...settings,
    locale: formatLocaleForI18n(settings.locale) as LanguageLocaleCode,
    configuration: addPorpertyToConfig(settings.configuration),
    loadingState: {
      ...loadingState,
      isError,
    },
  };

  return <settingsContext.Provider value={value}>{children}</settingsContext.Provider>;
};

export const useSettings = () => useContext(settingsContext);

export const SettingsConsumer = settingsContext.Consumer;

const renderError = (...logMessage: unknown[]) => {
  if (logMessage.length) console.error(...logMessage);
  // todo: show error screen
  return null;
};
