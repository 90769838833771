import { widgetTagNames } from '@guest-widgets/shared/src/config';
import { classes } from '@guest-widgets/shared/src/classes';

/** Side effect method for generating and returning widget intance ID
 *
 * @remarks it changes the HTML by adding a custom "data" attribute
 */
export const getInstanceId = (container: HTMLElement, hasShadowDom: boolean = false): number => {
  const widgetSelectors = { ...widgetTagNames, legacyBooking: `.${classes.booking.root}` };

  const attributeName = 'data-wid';

  const selector = Object.values(widgetSelectors)
    .map((tag) => `${tag}:not([${attributeName}])`)
    .join();

  const needsAttribute = !!document.querySelectorAll(selector).length;

  const webComponentContainer = hasShadowDom
    ? (container.getRootNode() as ShadowRoot).host
    : container.closest(Object.values(widgetTagNames).join());

  if (!needsAttribute) {
    const dataWid = (webComponentContainer ?? container).getAttribute(attributeName);
    return dataWid ? parseInt(dataWid) : 0;
  }

  const instanceId = getNewInstanceId(attributeName);

  //if didn't find a webcomponent container than it's legacy interface, simply set on the container
  (webComponentContainer ?? container).setAttribute(attributeName, instanceId.toString());

  return instanceId;
};

/** Provides a new instance ID for the widget based on the last instance ID found in the DOM */
const getNewInstanceId = (attribute: string) => {
  const elements = document.querySelectorAll(`[${attribute}]`);
  const wids = Array.from(elements).reduce((acc, curr) => {
    const wid = curr.getAttribute(attribute);
    return wid ? [...acc, parseInt(wid)] : acc;
  }, [] as number[]);

  if (wids.length) {
    return Math.max(...wids) + 1;
  }

  return 1;
};
