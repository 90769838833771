import { AppProps } from '@guest-widgets/catalog/src/App/App';

export const mapToLocation = (locationFilter?: string): AppProps['locationFilter'] => {
  if (!locationFilter) return undefined;

  const [longitude, latitude, radius, ...name] = locationFilter.split(' ');
  return {
    longitude,
    latitude,
    radius: Number.parseInt(radius),
    name: name.join(' '),
  };
};
