import React, { ReactNode, useEffect } from 'react';
import { TranslationsProvider } from '@eo-locale/react';

// noinspection ES6PreferShortImport
import {
  useTranslation,
  getAvailableLanguage,
  FetchTranslationsProps,
} from '../hooks/useTranslation';
import type { EoLocale } from '../i18n/i18n';

interface LocaleTranslatorProps extends FetchTranslationsProps {
  children: ReactNode;
  defaultLocale: EoLocale[];
}

export const LocaleTranslator = ({
  children,
  languageMap,
  locale,
  defaultLocale,
  includeSharedKeys = true,
}: LocaleTranslatorProps) => {
  const [translations, fetchTranslations] = useTranslation(defaultLocale);

  useEffect(() => {
    fetchTranslations({ languageMap, locale, includeSharedKeys });
  }, [locale]);

  return (
    <TranslationsProvider
      language={getAvailableLanguage(languageMap, locale)}
      locales={translations}
    >
      {children}
    </TranslationsProvider>
  );
};
