import React, { lazy, Suspense, useEffect, useState } from 'react';
import { WidgetContextProvider, useWidget } from '@guest-widgets/core';
import { ConfigurationSettingsDto } from '@guest-widgets/shared/src/types/__mocks__/ConfigurationSettingsDto';

import { LanguageLocaleCode } from '../i18n/i18n';

const BookingWidget = lazy(() => import('@guest-widgets/booking/src/App/App'));

interface BookingProps {
  productId: string;
  customerCode: string;
  locale: LanguageLocaleCode;
  subId?: string;
  configuration?: ConfigurationSettingsDto;
  enableFeatures?: string[];
  disableFeatures?: string[];
}

export const Booking = ({
  customerCode,
  locale,
  subId,
  configuration,
  productId,
  enableFeatures,
  disableFeatures,
}: BookingProps) => {
  const { stylesContainer, containerElement, instanceId } = useWidget();
  const [bookingContainer, setBookingContainer] = useState<HTMLElement>();
  const shadowRoot = containerElement.getRootNode() as ShadowRoot;
  const bookingId = 'booking-widget-container-' + instanceId;

  useEffect(() => {
    const widgetContainter = shadowRoot.getElementById(bookingId);
    if (!widgetContainter) return;
    setBookingContainer(widgetContainter);
  }, [shadowRoot.getElementById(bookingId)]);

  return (
    <div id={bookingId} data-wid={instanceId}>
      {bookingContainer && (
        <WidgetContextProvider
          element={bookingContainer}
          containerElement={bookingContainer}
          viewportElement={bookingContainer}
          stylesContainer={stylesContainer}
          hasShadowDom={false}
        >
          <Suspense fallback={null}>
            <BookingWidget
              customerCode={customerCode}
              locale={locale}
              subId={subId}
              configuration={mapConfigurationToWidget(configuration)}
              productId={productId}
              enableFeatures={enableFeatures}
              disableFeatures={disableFeatures}
            />
          </Suspense>
        </WidgetContextProvider>
      )}
    </div>
  );
};

/** Set fixed font size for booking-widget because it considers the parent (button-widget) font-size when calculating its own */
const mapConfigurationToWidget = (configuration?: ConfigurationSettingsDto) => {
  if (!configuration) return;

  return {
    ...configuration,
    fontSize: 1,
  };
};
