import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Text } from '@eo-locale/react';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { button } from '@guest-widgets/shared/src/classes/button';
import { LoadingButton } from '@guest-widgets/shared/src/components/LoadingButton';
import { elementArea, useWidget, widthToBreakpoint } from '@guest-widgets/core';

import { useSettings } from './contexts/settingsContext/settingsContext';
import { DesktopDialog } from './Dialog/DesktopDialog';
import { MobileDrawer } from './Dialog/MobileDrawer';
import { FloatingContainer } from './FloatingContainer';
import { useConversionBoosterStyles } from './hooks/useConversionBoosterStyles';
import { useAdjustZIndexDynamically } from './hooks/useAdjustZIndexDynamically';

export const ButtonWidget = () => {
  const labelRef = useRef<HTMLSpanElement>(null);
  const [width, setWidth] = useState(0);
  const conversionBoosterStyles = useConversionBoosterStyles();
  const { containerElement } = useWidget();
  const {
    size,
    label,
    loadingState: { isLoading, isFetching },
  } = useSettings();

  const { dialogRef, closeModal, openModal } = useAdjustZIndexDynamically();

  useEffect(() => {
    containerElement.style.display = 'flex';
    const offsetWidth = labelRef.current ? (labelRef.current.offsetWidth as number) : 0;
    setWidth(offsetWidth);

    const handleResize = () => {
      if (!isMobileSize()) {
        closeModal();
      }
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <FloatingContainer width={width}>
        <LoadingButton
          loading={isLoading && isFetching}
          size={size}
          disableElevation
          variant="contained"
          color="primary"
          onClick={openModal}
          className={button.root}
          startIcon={<ConfirmationNumberIcon />}
          sx={conversionBoosterStyles}
        >
          <span ref={labelRef}>{label || <Text id="book-now" />} </span>
        </LoadingButton>
      </FloatingContainer>
      <div ref={dialogRef}>
        {isMobileSize() ? (
          <MobileDrawer onClose={closeModal} />
        ) : (
          <DesktopDialog onClose={closeModal} />
        )}
      </div>
    </>
  );
};

const isMobileSize = () => {
  return elementArea(widthToBreakpoint(window.innerWidth)).isSmall;
};
