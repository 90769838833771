import { useEffect, useRef, useState } from 'react';

import { Breakpoint, widthToBreakpoint } from './theme/breakpoints';

export const useResizeObserver = (
  widget: HTMLElement,
  viewport: HTMLElement,
  sizeFactor?: number
) => {
  const timer = useRef(0);

  const [widgetArea, setWidgetBreakpoint] = useState(() => widthToBreakpoint(widget.offsetWidth));
  const [viewportArea, setViewportBreakpoint] = useState(() =>
    widthToBreakpoint(viewport.offsetWidth)
  );
  const [proportionalWidgetArea, setProportionalWidgetBreakpoint] = useState<Breakpoint>(() =>
    widthToBreakpoint(widget.offsetWidth)
  );

  useEffect(() => {
    if (!widget) return;
    const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }

      timer.current = window.setTimeout(() => {
        entries.forEach((entry) => {
          const widgetWidth = entry.contentRect.width;
          const deviceWidth = window.innerWidth;

          if (widgetWidth > deviceWidth) {
            setWidgetBreakpoint(widthToBreakpoint(deviceWidth));
            setProportionalWidgetBreakpoint(widthToBreakpoint(deviceWidth / (sizeFactor || 1)));
            return;
          }

          setWidgetBreakpoint(widthToBreakpoint(widgetWidth));
          setProportionalWidgetBreakpoint(widthToBreakpoint(widgetWidth / (sizeFactor || 1)));
        });
        setViewportBreakpoint(widthToBreakpoint(viewport.offsetWidth));
      }, 200);
    });
    observer.observe(widget);
    return () => observer.disconnect();
  }, [widget, sizeFactor]);

  return {
    widgetArea,
    viewportArea,
    proportionalWidgetArea,
  };
};
