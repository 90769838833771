import React from 'react';
import { ConfigurationTheme } from '@guest-widgets/shared/src/components/ConfigurationTheme/ConfigurationTheme';
import { QueryRouter } from '@guest-widgets/shared/src/components/Router/QueryRouter';

import { ButtonWidget } from './ButtonWidget';
import { SettingsFromProps } from './contexts/settingsContext/settings';
import { SettingsConsumer, SettingsProvider } from './contexts/settingsContext/settingsContext';
import { LocalizationProvider } from './LocalizationProvider';
import { ApiContextProvider } from './contexts/apiContext/apiContext';
import { ModalProvider } from './contexts/modalContext/modalContext';

export type AppProps = SettingsFromProps;

export const App = (props: AppProps) => (
  <QueryRouter>
    <ApiContextProvider>
      <SettingsProvider {...props}>
        <SettingsConsumer>
          {({ configuration, disableDownloadFont }) => (
            <LocalizationProvider>
              <ConfigurationTheme
                configuration={configuration}
                disableDownloadFont={disableDownloadFont}
              >
                <ModalProvider>
                  <ButtonWidget />
                </ModalProvider>
              </ConfigurationTheme>
            </LocalizationProvider>
          )}
        </SettingsConsumer>
      </SettingsProvider>
    </ApiContextProvider>
  </QueryRouter>
);
