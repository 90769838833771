import React, { PropsWithChildren } from 'react';
import {
  datadogRum,
  RumActionEvent,
  RumEvent,
  RumErrorEvent,
  RumResourceEvent,
  RumFetchResourceEventDomainContext,
  RumXhrResourceEventDomainContext,
  RumOtherResourceEventDomainContext,
  RumViewEvent,
  RumLongTaskEvent,
} from '@datadog/browser-rum';

import { config } from '../config';

const isDevelopment = import.meta.env.MODE === 'development';

/**
 * Application Performance Monitoring APM init
 */
export const APMInit = () => {
  if (isDevelopment) return;

  datadogRum.init({
    applicationId: 'fd8e5520-1468-49f5-b8a1-7a90e1390240',
    clientToken: 'pubd0dd286f90d28d1334629c2de6d67276',
    site: 'datadoghq.eu',
    service: 'guest-widgets',
    env: config.env,
    version: config.apmVersion,
    sessionSampleRate: 0,
    sessionReplaySampleRate: 0,
    trackUserInteractions: false,
    trackResources: false,
    trackLongTasks: false,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: (event, context) => {
      if (isActionEvent(event)) {
        return event.action.type == 'custom'; // event bubbling is prevented outside the shadow dom, gets only widget will manually triggered the events
      }

      if (isResourceEvent(event)) {
        return (
          !!(context as RumSourceContext).performanceEntry?.name?.includes(KEYWORD) ||
          !!(context as RumXhrResourceEventDomainContext).xhr?.responseURL?.includes(KEYWORD) ||
          !!(context as RumFetchResourceEventDomainContext).response?.url?.includes(KEYWORD)
        );
      }

      if (isErrorEvent(event)) {
        return (
          !!event.error.stack?.includes(KEYWORD) || !!event.error.handling_stack?.includes(KEYWORD)
        );
      }

      if (isViewEvent(event)) {
        return true; //gather domains where widgets are running
      }

      if (isLongTaskEvent(event)) {
        return false; // we have no way to reliably identify whether the long task is from the widget or the container
      }

      return false;
    },
    allowedTracingUrls: [(url) => url.includes(KEYWORD)],
  });

  /**It looks for resources that contains the specific keyword */
  const KEYWORD = 'guest';

  datadogRum.startSessionReplayRecording();
};

const isResourceEvent = (event: RumEvent): event is RumResourceEvent =>
  (event as RumResourceEvent).type === 'resource';

const isActionEvent = (event: RumEvent): event is RumActionEvent =>
  (event as RumActionEvent).type === 'action';

const isErrorEvent = (event: RumEvent): event is RumErrorEvent =>
  (event as RumErrorEvent).type === 'error';

const isViewEvent = (event: RumEvent): event is RumViewEvent =>
  (event as RumViewEvent).type === 'view';

const isLongTaskEvent = (event: RumEvent): event is RumLongTaskEvent =>
  (event as RumLongTaskEvent).type === 'long_task';

type RumSourceContext =
  | RumFetchResourceEventDomainContext
  | RumXhrResourceEventDomainContext
  | RumOtherResourceEventDomainContext;

export const ErrorBoundaryWrapper = ({ children }: PropsWithChildren<{}>) => <>{children}</>;
