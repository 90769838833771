/**
 * Retrieves the z-index of a given HTMLElement.
 * @param {HTMLElement} element - The HTML element for which to retrieve the z-index.
 * @returns {number} - The z-index of the element. Returns 0 if the z-index is not specified or cannot be parsed.
 */
export const getZIndex = (element: HTMLElement): number => {
  // Retrieve the computed style of the element and extract the z-index property
  const zIndex = parseInt(getComputedStyle(element).zIndex, 10);

  // Return 0 if the calculated z-index is not a valid number
  return isNaN(zIndex) ? 0 : zIndex;
};

/**
 * Recursively adjusts the z-index of parent elements to prevent stacking context issues.
 *
 * @param {HTMLElement} currentElement - The targeted element for which to adjust the z-index.
 * @param {Node} rootNode - The root node of the DOM tree or Shadow DOM. Used when the targeted element is within a Shadow DOM.
 * @param {() => void} [onBodyReached] - An optional callback function invoked when the function reaches the body element.
 */

const adjustZIndexRecursively = (
  currentElement: HTMLElement,
  rootNode: Node,
  onBodyReached?: () => void
) => {
  // Get the parent of a given element, which can be a normal HTML element or the host element of a ShadowRoot
  const parent = (rootNode instanceof ShadowRoot
    ? rootNode.host // Use rootNode.host for ShadowRoot
    : currentElement.parentElement) as HTMLElement;

  // Check if the 'parent' variable is a HTMLElement instance
  if (!(parent instanceof HTMLElement)) {
    // If 'parent' is not an HTMLElement, exit the function or block of code
    return;
  }
  // Stop if the parent is the body element
  if (parent.tagName.toLowerCase() === 'body') {
    // For the purpose of testing
    // Invoke the optional callback when the function reaches the body element
    onBodyReached?.();
    return;
  }
  // Retrieve an array of sibling elements (children of the same parent) excluding the current element (parent)
  const zIndexes = Array.from(parent.parentElement?.children ?? [])
    .filter(
      (sibling): sibling is HTMLElement => sibling instanceof HTMLElement && sibling !== parent
    )
    .map((sibling: HTMLElement) => getZIndex(sibling));

  // Set the z-index of the parent element to be one more than the maximum z-index among its siblings
  parent.style.zIndex = String(Math.max(...zIndexes) + 1);

  // Continue recursively adjusting the z-index for parent elements
  adjustZIndexRecursively(parent, parent.getRootNode(), onBodyReached);
};

/**
 * This function is created to address a recurring display issue caused by the stacking context (z-index).
 * It traverses the DOM tree from the targeted element to the body, checking at each step the
 * parent's z-index and comparing it to its siblings' z-indexes.
 * If the parent's z-index is not the highest among its siblings, it adds 1 to the highest sibling's z-index.
 */
export const adjustParentZIndexRecursively = (element: HTMLElement, onBodyReached?: () => void) => {
  // This root node is used when the targeted element is the root of the Shadow DOM.
  const rootNode = element.getRootNode();
  adjustZIndexRecursively(element, rootNode, onBodyReached);
};
