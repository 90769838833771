import { config } from '../../config';

export const downloadFont = (fontFamily: string) => {
  const head = document.getElementsByTagName('head')[0];
  const fontNames = getFontsToLoad(fontFamily);

  if (!head || fontNames.length === 0) return;

  fontNames.forEach((fontName) => {
    if (elementExists(fontName)) return;

    const fontNameNoSpaces = fontName.replace(/ /g, '');

    const fontLink = document.createElement('link');
    fontLink.rel = 'stylesheet';
    fontLink.href = `${config.sharedResourcesBaseUrl}/fonts/${fontNameNoSpaces}.css`;
    head.appendChild(fontLink);
  });
};

// exported only for testing purposes
export const getFontsToLoad = (fontFamily: string): string[] =>
  fontFamily
    .split(',')
    .map((fontName) => fontName.trim().replace(/"/g, ''))
    .filter(isDownloadble);

const nonDownloadbleFonts = ['arial', 'sans-serif', 'serif'];
const isDownloadble = (fontName: string) => !nonDownloadbleFonts.includes(fontName.toLowerCase());

const elementExists = (fontName: string): boolean => {
  const fontNameNoSpaces = fontName.replace(/ /g, '');
  const fontNameWithPlus = fontName.replace(/ /g, '\\+');
  const fontNameWithUTF8 = fontName.replace(/ /g, '%20');
  const fontNameTest = new RegExp(
    `${fontName}|${fontNameNoSpaces}|${fontNameWithPlus}|${fontNameWithUTF8}`
  );

  const allLinks = Array.from(document.getElementsByTagName('link'));
  const sameFontNameElements = allLinks.filter((link) => fontNameTest.test(link.href));

  return !!sameFontNameElements.length;
};
