/** It calculates the font-size based on the "configuration.fontSize" value */
export const calcCssSize = (cssSize: number | string | undefined, multiplier: number) => {
  if (typeof cssSize === 'undefined') return undefined;
  if (multiplier <= 0) return 'unset';
  return typeof cssSize === 'number' ? cssSize * multiplier : `calc(${cssSize} * ${multiplier})`;
};

/** It calculates the border-radius values to be applied in elements of different heights
 * in order to keep a consistent visual aspect.
 */
export const calcBorderRadius = (heightFactor: number, borderRadius: number | undefined) => {
  if (typeof borderRadius === 'undefined') return 4;
  // It forces "circular" border radius for edge cases (e.g. fontFamily: 'Poppins' && fontSize: 'XL')
  if (borderRadius === 1) return 999;
  return `calc(${heightFactor}em * ${borderRadius})`;
};
/** It calculates the border-radius values using the "theme.shape.borderRadius" value to get
 * "configuration.borderRadius" and calculate the border-radius to be applied in the element.
 */
export const calcBorderRadiusFromShape = (heightFactor: number, shapeBorderRadius: number) => {
  if (typeof shapeBorderRadius === 'undefined') return 4;
  const configBorderRadius = shapeBorderRadius / 20;
  return calcBorderRadius(heightFactor, configBorderRadius);
};

export const CUSTOM_INPUT_HEIGHT_FACTOR = 1.3;
export const CUSTOM_BUTTON_HEIGHT_FACTOR = 1.5;
export const BOOKING_BUTTON_HEIGHT_FACTOR = 1.3;
export const SELECTOR_BUTTON_GROUP_HEIGHT_FACTOR = 1.4;
export const SELECTOR_BUTTON_HEIGHT_FACTOR = 1.1;
export const COUPON_LARGE_BUTTON_HEIGHT_FACTOR = 1.5;
export const BOOK_MOBILE_BUTTON_HEIGHT_FACTOR = 1.7;
