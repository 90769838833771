import React, { useEffect, useState, lazy, Suspense } from 'react';
import { WidgetContextProvider, useWidget } from '@guest-widgets/core';
import { CategoryPrefilterDto } from '@checkfront/guest-widgets-editor-api-api-client-javascript-axios';
import { ConfigurationSettingsDto } from '@guest-widgets/shared/src/types/__mocks__/ConfigurationSettingsDto';

import { useSettings } from '../contexts/settingsContext/settingsContext';

const CatalogWidget = lazy(() => import('@guest-widgets/catalog/src/App/App'));

const Catalog = () => {
  const {
    customerCode,
    locale,
    subId,
    configuration,
    enableFeatures,
    disableFeatures,
    categoryPrefilter,
    locationPrefilter,
  } = useSettings();
  const { stylesContainer, containerElement, instanceId } = useWidget();
  const [catalogContainer, setCatalogContainer] = useState<HTMLElement>();
  const shadowRoot = containerElement.getRootNode() as ShadowRoot;
  const catalogId = 'catalog-widget-container-' + instanceId;

  useEffect(() => {
    const widgetContainter = shadowRoot.getElementById(catalogId);
    if (!widgetContainter) return;
    setCatalogContainer(widgetContainter);
  }, [shadowRoot.getElementById(catalogId)]);

  return (
    <div id={catalogId} data-wid={instanceId}>
      {catalogContainer && (
        <WidgetContextProvider
          element={catalogContainer}
          containerElement={catalogContainer}
          viewportElement={catalogContainer}
          stylesContainer={stylesContainer}
          hasShadowDom={false}
        >
          <Suspense fallback={null}>
            <CatalogWidget
              customerCode={customerCode}
              locale={locale}
              categoryFilter={mapToCategoryFilter(categoryPrefilter)}
              locationFilter={locationPrefilter}
              subId={subId}
              configuration={mapConfigurationToWidget(configuration)}
              enableFeatures={enableFeatures}
              disableFeatures={disableFeatures}
            />
          </Suspense>
        </WidgetContextProvider>
      )}
    </div>
  );
};

export default Catalog;

/** Set fixed font size for catalog-widget because it considers the parent (button-widget) font-size when calculating its own */
const mapConfigurationToWidget = (configuration?: ConfigurationSettingsDto) => {
  if (!configuration) return;

  return {
    ...configuration,
    fontSize: 1,
  };
};

const mapToCategoryFilter = (categoryPrefilter?: CategoryPrefilterDto[]) => {
  return categoryPrefilter?.map(({ id }) => id);
};
