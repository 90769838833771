import {
  WidgetsApi,
  Configuration,
} from '@checkfront/guest-widgets-editor-api-api-client-javascript-axios';

const config: Configuration = {
  basePath: import.meta.env.VITE_EDITOR_BACKEND_URL,
  isJsonMime: () => true,
};

export const widgetEditorApi = new WidgetsApi(config);
