import { WindowWithPackages } from '../types/windowWithPackages';

export const isPackageLoaded = (packageName: string) => {
  const localWindow = (window as any) as WindowWithPackages;
  localWindow.loadedPackageList = localWindow.loadedPackageList || {};
  return Boolean(localWindow.loadedPackageList[packageName]);
};

export const checkLoadedAndRegister = (packageName: string) => {
  const loaded = isPackageLoaded(packageName);
  const localWindow = (window as any) as WindowWithPackages;
  localWindow.loadedPackageList[packageName] = true;
  return loaded;
};
