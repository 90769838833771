import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';

import { urlSearchParams } from '../../utils/urlSearchParams';

export interface QueryRouterContext extends ReturnType<typeof urlSearchParams> {}

const queryRouterContext = createContext({} as QueryRouterContext);

export const QueryRouter = ({ children }: PropsWithChildren<{}>) => {
  const router = useQueryRouter();
  const [, forceRerender] = useState<{}>();
  const searchParams = urlSearchParams();

  useEffect(() => {
    const popStateListener = () => {
      forceRerender({});
    };

    window.addEventListener('popstate', popStateListener);
    return () => window.removeEventListener('popstate', popStateListener);
  }, []);

  if (Object.keys(router).length) {
    // We already have a parent router, so just forward the children without any additional ado
    // Otherwise the outer router would be updated and rerender
    return <>{children}</>;
  }

  const set = (key: string, value: string) => {
    searchParams.set(key, value);
    forceRerender({});
  };

  const setMultiple: QueryRouterContext['setMultiple'] = (...entries) => {
    searchParams.setMultiple(...entries);
    forceRerender({});
  };

  const del = (key: string) => {
    searchParams.delete(key);
    forceRerender({});
  };

  const value: QueryRouterContext = {
    ...searchParams,
    set,
    setMultiple,
    delete: del,
  };

  return <queryRouterContext.Provider value={value}>{children}</queryRouterContext.Provider>;
};

export const useQueryRouter = () => useContext(queryRouterContext);
