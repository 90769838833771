export const copyCustomStyles = (source: string | HTMLElement, container: HTMLElement) => {
  const element = getElement(source);

  if (!element) return;

  container.innerHTML = '';
  element.querySelectorAll('link, style').forEach((tag) => container.append(tag.cloneNode(true)));
};

const getElement = (source: string | HTMLElement) => {
  if (source instanceof HTMLElement) return source;

  const element = document.getElementById(source);

  if (element instanceof HTMLTemplateElement) return element.content;

  return false;
};
