import { useEffect } from 'react';

export const useDisableScroll = (disable: boolean) => {
  const bodyStyle = document.body.style;

  useEffect(() => {
    bodyStyle.overflow = disable ? 'hidden' : 'auto';

    return () => {
      bodyStyle.overflow = 'auto';
    };
  }, [disable]);
};
