import { Box } from '@mui/material';
import { Booking as BookingComponent } from '@guest-widgets/shared/src/components/Booking';

import { useSettings } from '../contexts/settingsContext/settingsContext';

const Booking = () => {
  const {
    customerCode,
    locale,
    subId,
    configuration,
    productId,
    enableFeatures,
    disableFeatures,
  } = useSettings();

  return (
    <Box px={4}>
      <BookingComponent
        customerCode={customerCode}
        locale={locale}
        subId={subId}
        configuration={configuration}
        productId={productId}
        enableFeatures={enableFeatures}
        disableFeatures={disableFeatures}
      />
    </Box>
  );
};

export default Booking;
