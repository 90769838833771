import { PropsWithChildren } from 'react';

import { styled } from './theme/styled';

/** Component to address fixes for integration issues, to ensure a clean integration. */
export const IntegrationGuard = ({ children }: PropsWithChildren<{}>) => (
  <ContainerForFlexParent>{children}</ContainerForFlexParent>
);

/** Guarantee all widgets will have at maximum width the same width of the device
 * even inside of a flex parent.
 */
const ContainerForFlexParent = styled('div')({
  maxWidth: '100vw',
});
