export const button = {
  prefix: 'Gw-bt',
  root: 'guest-button-widget',
  dialog: {
    root: 'guest-button-dialog',
    close: 'guest-button-dialog-close',
    content: 'guest-button-dialog-content',
  },
  drawer: {
    root: 'guest-button-drawer',
    header: 'guest-button-drawer-header',
    content: 'guest-button-drawer-content',
    booking: 'guest-button-drawer-booking',
  },
};
