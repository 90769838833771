import React, { lazy, useEffect, useState, Suspense } from 'react';
import { useWidget, WidgetContextProvider } from '@guest-widgets/core';

import { useSettings } from '../contexts/settingsContext/settingsContext';

const ProductWidget = lazy(() => import('@guest-widgets/product/src/App/App'));

export const Product = () => {
  const {
    customerCode,
    locale,
    subId,
    configuration,
    productId,
    disableFeatures,
    enableFeatures,
  } = useSettings();
  const { stylesContainer, containerElement, instanceId } = useWidget();
  const [productContainer, setProductContainer] = useState<HTMLElement>();
  const shadowRoot = containerElement.getRootNode() as ShadowRoot;

  useEffect(() => {
    const widgetContainer = shadowRoot.getElementById(productId);
    if (!widgetContainer) return;
    setProductContainer(widgetContainer);
  }, [shadowRoot.getElementById(productId)]);

  return (
    <div id={productId} data-wid={instanceId}>
      {productContainer && (
        <WidgetContextProvider
          element={productContainer}
          containerElement={productContainer}
          viewportElement={productContainer}
          stylesContainer={stylesContainer}
          hasShadowDom={false}
        >
          <Suspense fallback={null}>
            <ProductWidget
              customerCode={customerCode}
              locale={locale}
              subId={subId}
              configuration={configuration}
              productId={productId}
              disableFeatures={disableFeatures}
              enableFeatures={enableFeatures}
            />
          </Suspense>
        </WidgetContextProvider>
      )}
    </div>
  );
};

export default Product;
