import React, { PropsWithChildren } from 'react';
import { styled } from '@guest-widgets/core/src/theme/styled';

import { useSettings } from './contexts/settingsContext/settingsContext';

const BUTTON_PADDING_WIDTH = 134;

/** It positions button accordingly to floating property */
export const FloatingContainer = ({ children, width }: PropsWithChildren<{ width: number }>) => {
  const { floating } = useSettings();

  if (floating?.position === 'BottomRight')
    return <BottomRight style={{ minWidth: width + BUTTON_PADDING_WIDTH }}>{children}</BottomRight>;
  if (floating?.position === 'MiddleRight') return <MiddleRight>{children}</MiddleRight>;

  return (
    <DefaultContainer style={{ minWidth: width + BUTTON_PADDING_WIDTH }}>
      {children}
    </DefaultContainer>
  );
};

const BottomRight = styled('div')(({ theme: { spacing }, widgetArea }) => ({
  position: 'fixed',
  bottom: spacing(2),
  display: 'flex',
  justifyContent: 'center',
  aliginItems: 'center',

  ...(widgetArea.isSmall
    ? { left: '50%', transform: 'translate(-50%, 0)' }
    : { right: spacing(2) }),
}));

const MiddleRight = styled('div')({
  position: 'fixed',
  transformOrigin: 'top right',
  right: 0,
  top: '50%',
  transform: 'rotate(90deg) translateX(50%)',
});

const DefaultContainer = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  aliginItems: 'center',
});
