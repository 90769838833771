import { useState } from 'react';

import { config } from '../config';
import { EoLocale, sharedMessages } from '../i18n/i18n';
import { LanguageLocaleCode, LanguageMap } from '../i18n/i18n';

export interface FetchTranslationsProps {
  languageMap: LanguageMap;
  locale?: string;
  includeSharedKeys?: boolean;
}

export const useTranslation = (defaultValue: EoLocale[]) => {
  const [translations, setTranslations] = useState(defaultValue);

  const fetchTranslations = async (props: FetchTranslationsProps) => {
    const newTranslations = await getTranslations(props);
    setTranslations(newTranslations);
  };

  return [translations, fetchTranslations] as const;
};

const browserLanguage = navigator.language;

/**It returns based on a given locale string the closest possible supported language_locale */
export const getAvailableLanguage = (
  languageMap: LanguageMap,
  locale: string = browserLanguage
): LanguageLocaleCode => {
  if (locale in languageMap) return locale as LanguageLocaleCode;

  //if didn't find based on locale, tries to find based only in language
  const [languageCode] = locale.split('-');
  if (languageCode in languageMap) return languageCode as LanguageLocaleCode;

  //otherwise fallbacks to default language
  return config.fallbackLanguage as LanguageLocaleCode;
};

const getTranslations = async ({
  languageMap,
  locale,
  includeSharedKeys,
}: FetchTranslationsProps): Promise<EoLocale[]> => {
  const language = getAvailableLanguage(languageMap, locale);
  const messages = await languageMap[language]();
  const shared = includeSharedKeys
    ? await sharedMessages[getAvailableLanguage(sharedMessages, locale)]()
    : {};

  return [
    {
      language,
      messages: { ...shared, ...messages },
    },
  ];
};
