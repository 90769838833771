import { createTheme as createMuiTheme } from '@mui/material/styles';

import { mapThemeToPalette, ThemeType } from './themeMapper';

declare module '@mui/material/styles' {
  interface Theme extends CustomTheme {}
  interface DeprecatedThemeOptions extends Partial<CustomTheme> {}
}

export interface CustomTheme {
  border: string;
}

export interface createThemeProps {
  themeType?: ThemeType;
  containerElement?: HTMLElement;
  hasShadowDom?: boolean;
}

export const createTheme = ({
  themeType,
  containerElement,
  hasShadowDom,
}: createThemeProps = {}) => {
  const mappedPalette = mapThemeToPalette(themeType);
  const baseFontSize = 14;
  const theme = createMuiTheme({
    spacing: 4,
    palette: {
      ...mappedPalette,
    },
    typography: {
      fontWeightMedium: 600, // Include any new typography on ConfigurationTheme too
      fontSize: baseFontSize,
      h1: {
        fontSize: baseFontSize * 2.43,
      },
      h2: {
        fontSize: baseFontSize * 1.714,
      },
      h3: {
        fontSize: baseFontSize * 1.143,
      },
      h4: {
        fontSize: baseFontSize * 1.143,
      },
      h5: {
        fontSize: baseFontSize,
      },
      h6: {
        fontSize: baseFontSize * 0.875,
      },
      body1: {
        fontSize: baseFontSize * 1.143,
      },
      body2: {
        fontSize: baseFontSize,
      },
      caption: {
        fontSize: baseFontSize * 0.875,
      },
      subtitle1: {
        fontSize: baseFontSize,
      },
    },
    shape: {
      borderRadius: 4,
    },
  });

  const { typography, palette, spacing } = theme;

  theme.border = `2px solid ${palette.divider}`;

  theme.components = {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontWeight: typography.fontWeightBold,
        },
        h2: {
          fontWeight: typography.fontWeightMedium,
        },
        h3: {
          fontWeight: typography.fontWeightBold,
        },
        h4: {
          fontWeight: typography.fontWeightMedium,
        },
        h5: {
          fontWeight: typography.fontWeightMedium,
        },
        h6: {
          fontWeight: typography.fontWeightMedium,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: typography.fontSize,
          fontWeight: typography.fontWeightMedium,
        },
        containedPrimary: {
          color: palette.common.white,
          fontWeight: typography.fontWeightMedium,
          '&:disabled': {
            color: palette.background.paper,
            fontWeight: typography.fontWeightMedium,
            backgroundColor: palette.secondary.light,
            opacity: 0.6,
          },
        },
        outlinedPrimary: {
          borderWidth: 2,
        },
        outlinedSecondary: {
          border: theme.border,
          '&:hover': {
            border: theme.border,
          },
        },
        text: {
          paddingRight: '1em',
          paddingLeft: '1em',
        },
        startIcon: {
          marginLeft: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: typography.fontSize * 1.714,
        },
        fontSizeSmall: {
          fontSize: typography.fontSize * 1.5,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: typography.fontSize * 0.875,
        },
        outlined: {
          fontWeight: 500,
          border: theme.border,
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        container: containerElement,
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: palette.background.default,
          marginTop: spacing(2),
          padding: spacing(4),
        },
      },
      defaultProps: {
        container: containerElement,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: palette.background.paper,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderWidth: 1,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
        },
        valueLabel: {
          top: '-6px',
          left: 'auto',
          fontSize: typography.fontSize,
          borderRadius: '4px',
          width: 'auto',
          transform: 'none',
          padding: '5px',
          whiteSpace: 'nowrap',
          backgroundColor: palette.text.primary,
          '&:before': {
            display: 'none',
          },
        },
        markLabel: {
          fontSize: typography.fontSize * 0.875,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: palette.divider,
          borderWidth: 2,
        },
        adornedStart: {
          '&[class*="MuiAutocomplete-inputRoot"][class*="MuiOutlinedInput-marginDense"]': {
            paddingLeft: '14px !important',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          gap: 4,
          alignItems: 'center',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: 'block',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorInherit: {
          opacity: 1,
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiModal: {
      defaultProps: {
        container: containerElement,
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        disablePortal: true,
      },
    },
    MuiTooltip: {
      defaultProps: {
        PopperProps: {
          disablePortal: true,
        },
      },
    },
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: {
          all: hasShadowDom ? 'initial' : undefined,
          fontSize: baseFontSize,
          textAlign: 'left',
          boxSizing: 'border-box',
          fontFamily: `"Open Sans", "Roboto", "Helvetica Neue", sans-serif`,
          backgroundColor: 'transparent',
          '& *': {
            margin: 0,
            textDecoration: 'none',
            color: 'inherit',
          },
          '& input': {
            boxSizing: 'content-box',
          },
          '& a:focus, & a:hover, & a:active': {
            color: 'inherit',
            textDecoration: 'none',
            outline: 'none',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          cursor: 'pointer',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          lineHeight: 1,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
  };

  return theme;
};
