import React from 'react';
import { styled } from '@mui/material/styles';
import { Grow, IconButton as MuiIconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { button } from '@guest-widgets/shared/src/classes/button';

import { useModalState } from '../contexts/modalContext/modalContext';
type CloseButtonProps = {
  onClose: () => void;
};

export const CloseButton = ({ onClose }: CloseButtonProps) => {
  const { isOpen } = useModalState();
  return (
    <Grow in={isOpen} timeout={1000}>
      <IconButton aria-label="close" onClick={onClose} className={button.dialog.close} size="large">
        <CloseIcon fontSize="small" />
      </IconButton>
    </Grow>
  );
};

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  position: 'absolute',
  color: 'inherit',
  top: 0,
  right: 0,
  zIndex: 10,
  width: 25,
  height: 25,
  borderRadius: '0 0 0 50%',
  padding: 16,
  backgroundColor: theme.palette.background.paper,
}));
