import { useRef, useEffect, useState } from 'react';
import { adjustParentZIndexRecursively } from '@guest-widgets/shared/src/utils/adjustParentZIndexRecursively';
import { v4 as uuidv4 } from 'uuid';

import { useModalState } from '../contexts/modalContext/modalContext';

// The map keys are UUID-generated IDs used to uniquely identify individual components
const mountedComponents: Map<string, any> = new Map();

export const useAdjustZIndexDynamically = () => {
  const [adjustZIndexEnabled, setAdjustZIndexEnabled] = useState(false);
  const { onClose, onOpen } = useModalState();
  const [componentIdentifier, setComponentIdentifier] = useState('');

  // Get dialog ref in order to be used in the adjustParentZIndexRecursively later on
  const dialogRef = useRef<HTMLDivElement | null>(null);

  const openModal = () => {
    setAdjustZIndexEnabled(true);
    onOpen();
  };

  const closeModal = () => {
    setAdjustZIndexEnabled(false);
    onClose();
  };

  /**
   * This is used to clear the map before the adjustParentZIndexRecursively call to ensure
   * it is invoked when we click two button instances in succession and then return to the first one
   */
  const clearMountedComponents = () => {
    mountedComponents.clear();
  };

  /**
   * Add component id as Map key in order to control adjustParentZIndexRecursively function call.
   * The component will only be added either when clicking the button initially
   * or when clicking another instance and then returning to click the same button again.
   */
  const mount = (componentId: string) => {
    mountedComponents.set(componentId, '');
  };

  // Generate a unique identifier for button instance
  useEffect(() => {
    const id = uuidv4();
    setComponentIdentifier(id);
  }, []);

  useEffect(() => {
    const dialogElement = dialogRef.current;
    /**
     * for performance sake adjustParentZIndexRecursively is triggred only when:
     * click the button
     * button instance mounts for the first time thanks to this !mountedComponents.has(componentIdentifier)
     */
    if (dialogElement && adjustZIndexEnabled && !mountedComponents?.has(componentIdentifier)) {
      // unmount all other instances
      clearMountedComponents();
      // add targeted instance
      mount(componentIdentifier);
      // adjust the zindex
      adjustParentZIndexRecursively(dialogElement);
    }
  }, [dialogRef.current, adjustZIndexEnabled]);

  return { dialogRef, openModal, closeModal };
};
