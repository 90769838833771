import { useState } from 'react';

import { useLocalStorage } from './useLocalStorage';

interface ModalHandler {
  modalOpen: boolean;
  instanceId: number;
}

/** Hook to handle state of a modal or dialog keeping its state in LocalStorage
 * when 'persisted' property is true, and on hook state when it is false.
 * This apprach is to avoid unwanted state change on redirects or refresh page.
 */
export const useModalHandler = (instanceId: number, persisted?: boolean) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalHandler, setModalHandler] = useLocalStorage<ModalHandler | undefined>(
    'modalHandler',
    undefined
  );

  const setModalOpen = (modalOpen: boolean) => {
    setModalHandler({ modalOpen, instanceId });
    setIsOpen(modalOpen);
  };

  if (persisted && modalHandler?.instanceId === instanceId) {
    return [modalHandler.modalOpen, setModalOpen] as const;
  }

  return [isOpen, setModalOpen] as const;
};
