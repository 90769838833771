import { ToggleFeatureDto } from '@checkfront/guest-widgets-editor-api-api-client-javascript-axios';
import { ThemeType } from '@guest-widgets/core/src/theme/themeMapper';

export enum SharedFeature {
  ShadowDom = 'shadow-dom',
}

export type Feature = SharedFeature | ToggleFeatureDto;

export const Feature = { ...SharedFeature, ...ToggleFeatureDto };

const enableAllFeatures = () =>
  Object.values(Feature).reduce((acc: any, curr) => ((acc[curr] = true), acc), {});

export const defaultSharedFeatures: Record<keyof Feature, true> = {
  ...enableAllFeatures(),
  [ThemeType.DarkMode]: false,
};
