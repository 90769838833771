import React from 'react';
import MuiDialog, { DialogProps } from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import { button } from '@guest-widgets/shared/src/classes/button';

import { ContentWidget } from '../ContentWidget/ContentWidget';
import { WidgetType } from '../contexts/settingsContext/settings';
import { useSettings } from '../contexts/settingsContext/settingsContext';
import { useModalState } from '../contexts/modalContext/modalContext';

import { CloseButton } from './CloseButton';

type DesktopDialogProps = {
  onClose: () => void;
};
export const DesktopDialog = ({ onClose }: DesktopDialogProps) => {
  const { isOpen } = useModalState();
  const { widgetType: content } = useSettings();

  return (
    <Dialog
      fullWidth
      maxWidth={dialogWidthMapper[content]}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="button-widget"
      className={button.dialog.root}
      disableEnforceFocus
    >
      <DialogContent className={button.dialog.content}>
        <CloseButton onClose={onClose} />
        <ContentWidget />
      </DialogContent>
    </Dialog>
  );
};

const dialogWidthMapper: Record<WidgetType, DialogProps['maxWidth']> = {
  Booking: 'sm',
  Product: 'lg',
  Catalog: 'xl',
};

const DialogContent = styled(MuiDialogContent)(({ theme }) => ({
  margin: theme.spacing(4, 0, 0, 0),
  padding: 0,
}));

const CATALOG_PERFECT_FIT_WIDTH = 1240;

const Dialog = styled(MuiDialog)({
  '& .MuiDialog-paperWidthXl': {
    maxWidth: CATALOG_PERFECT_FIT_WIDTH,
  },
});
