import uniqueId from 'lodash/uniqueId';

export function numberToLetter(number: number) {
  if (typeof number !== 'number' || number < 1) {
    throw new Error('Number must be a positive integer.');
  }

  let result = '';

  while (number > 0) {
    const remainder = (number - 1) % 26; // Get the remainder in the range [0, 25]
    result = String.fromCharCode(97 + remainder) + result; // Convert to letter and prepend
    number = Math.floor((number - 1) / 26); // Move to the next group
  }

  return result;
}

export function uniqueCharId(prefix?: string) {
  const id = uniqueId();
  const key = isNaN(parseInt(id)) ? id : numberToLetter(parseInt(id));

  return (prefix ?? '') + key;
}
