import { booking } from './classes/booking';
import { catalog } from './classes/catalog';
import { details } from './classes/details';
import { button } from './classes/button';
import { calendar } from './classes/calendar';

export const classes = {
  details,
  catalog,
  booking,
  button,
  calendar,
};
