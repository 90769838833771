import { PaletteOptions } from '@mui/material/styles';

export enum ThemeType {
  DefaultMode = 'default-mode',
  DarkMode = 'dark-mode',
}

export const themeMap: Record<ThemeType, PaletteOptions> = {
  'default-mode': {
    mode: 'light',
    primary: {
      main: '#1D2939',
    },
    secondary: {
      main: '#444F61',
    },
    warning: {
      main: '#FDB656',
    },
    error: {
      main: '#B40B0B',
    },
    text: {
      primary: '#111725',
      secondary: '#6D7683',
    },
    info: {
      main: '#EEEEEE',
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
  },
  'dark-mode': {
    mode: 'dark',
    primary: {
      main: '#95E7A2',
    },
    secondary: {
      main: '#6d6d6d',
    },
    warning: {
      main: '#FFE886',
    },
    error: {
      main: '#FF9B87',
    },
    text: {
      primary: '#E0E0E0',
      secondary: '#AEAEAE',
    },
    info: {
      main: '#343b46',
    },
    background: {
      paper: '#131313',
      default: '#23272c',
    },
    divider: '#444F61',
  },
};

export const mapThemeToPalette = (theme?: ThemeType) =>
  themeMap[theme!] || themeMap[ThemeType.DefaultMode];
