export const urlSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const updateUrl = () => {
    const nextUrl = createUrl(searchParams.toString());
    window.history.pushState(null, window.document.title, nextUrl);
  };

  const createUrl = (queryString: string) => {
    const { pathname } = window.location;
    return `${pathname}${queryString && '?'}${queryString}`;
  };

  return {
    get: (key: string) => searchParams.get(key),

    set: (key: string, value: string) => {
      searchParams.set(key, value);
      updateUrl();
    },

    setMultiple: (...entries: ([key: string, value: string | undefined] | undefined | false)[]) => {
      entries.forEach((entry) => {
        if (entry) {
          const [key, value] = entry;
          if (typeof value === 'undefined') searchParams.delete(key);
          else searchParams.set(key, value);
        }
      });
      updateUrl();
    },

    getUrl: (key: string, value: string) => {
      const { search } = window.location;
      const query = new URLSearchParams(search);
      query.set(key, value);
      return createUrl(query.toString());
    },

    delete: (key: string) => {
      searchParams.delete(key);
      updateUrl();
    },

    has: (key: string) => searchParams.has(key),
  };
};
