import type { WidgetType } from '../contexts/settingsContext/settings';

/**Make for the html interface case insensitive  */
export const mapToWidgetType = (value?: string): WidgetType | undefined => {
  if (!value) return;

  const mapper: Record<string, WidgetType> = {
    booking: 'Booking',
    product: 'Product',
    catalog: 'Catalog',
  };

  return mapper[value.trim().toLocaleLowerCase()];
};
