export const catalog = {
  prefix: 'Gw-c',
  root: 'guest-catalog',
  header: 'guest-catalog-header',
  list: 'guest-catalog-list',
  filters: {
    section: {
      root: 'guest-catalog-filters-section',
      header: 'guest-catalog-filters-section-header',
      body: 'guest-catalog-filters-section-body',
    },
    popup: {
      root: 'guest-catalog-filters-popup',
      header: 'guest-catalog-filters-popup-header',
      body: 'guest-catalog-filters-popup-body',
    },
    slidingDrawer: {
      root: 'guest-catalog-filters-sliding-drawer',
      header: 'guest-catalog-filters-sliding-drawer-header',
      body: 'guest-catalog-filters-sliding-drawer-body',
      button: 'guest-catalog-filters-sliding-drawer-button',
    },
    applied: {
      root: 'guest-catalog-filters-applied',
      chip: 'guest-catalog-filters-applied-chip',
      reset: 'guest-catalog-filters-applied-reset',
    },
    option: 'guest-catalog-filters-option',
    mobile: {
      button: 'guest-catalog-filters-mobile-button',
      badge: 'guest-catalog-filters-mobile-badge',
    },
    more: {
      button: 'guest-catalog-filters-more-button',
      popup: 'guest-catalog-filters-more-popup',
    },
    categories: {
      root: 'guest-catalog-filters-categories',
      input: 'guest-catalog-filters-categories-input',
    },
    location: {
      root: 'guest-catalog-filters-location',
      input: 'guest-catalog-filters-location-input',
      suggestions: 'guest-catalog-filters-location-suggestions',
    },
    radius: {
      root: 'guest-catalog-filters-radius',
      input: 'guest-catalog-filters-radius-input',
    },
    dates: {
      root: 'guest-catalog-filters-dates',
      input: 'guest-catalog-filters-dates-input',
      chip: 'guest-catalog-filters-dates-chip',
    },
    sorting: {
      root: 'guest-catalog-filters-sorting',
      button: 'guest-catalog-filters-sorting-button',
    },
    price: 'guest-catalog-filters-price',
    misc: 'guest-catalog-filters-misc',
    languages: 'guest-catalog-filters-languages',
  },
  product: {
    root: 'guest-list-product',
    image: 'guest-list-product-image',
    content: 'guest-list-product-content',
    details: 'guest-list-product-details',
    header: 'guest-list-product-header',
    description: 'guest-list-product-description',
    rating: 'guest-list-product-rating',
    shortInfo: {
      root: 'guest-list-product-short-info',
      duration: 'guest-list-product-duration',
      location: 'guest-list-product-location',
    },
    footer: 'guest-list-product-footer',
    button: 'guest-list-product-button',
    price: {
      root: 'guest-list-product-price',
      label: 'guest-list-product-price-label',
      value: 'guest-list-product-price-value',
    },
    labels: {
      soldOut: 'guest-list-product-label-sold-out',
      saleEnded: 'guest-list-product-label-sold-sale-ended',
      new: 'guest-list-product-label-new',
      bestseller: 'guest-list-product-label-bestseller',
    },
  },
  pagination: {
    statusbar: 'guest-catalog-statusbar',
    button: 'guest-catalog-load-more-button',
  },
};
