import { createTheme, Theme } from '@mui/material';
import type { ConfigurationSettingsDto } from '@guest-widgets/shared/src/types/__mocks__/ConfigurationSettingsDto';

import {
  calcBorderRadius,
  calcCssSize,
  CUSTOM_BUTTON_HEIGHT_FACTOR,
  CUSTOM_INPUT_HEIGHT_FACTOR,
} from '../../utils/themeCalculator';

/**It create a new theme based on the ConfigurationSettings */
export const customTheme = (
  baseTheme: Theme,
  {
    accentColor = baseTheme.palette.primary.main,
    fontFamily = baseTheme.typography.fontFamily!,
    fontSize: fontSizeFactor = 1,
    fontColor,
    backgroundColor,
    borderRadius,
  }: ConfigurationSettingsDto
) => {
  const { palette, typography, components } = baseTheme;
  const { h1, h2, h3, h4, h5, h6, body1, body2, caption, subtitle1 } = typography;

  const theme = createTheme({
    ...baseTheme,
    palette: {
      ...palette,
      primary: {
        main: accentColor,
      },
    },
    typography: {
      fontWeightMedium: typography.fontWeightMedium,
      fontFamily,
      fontSize: typography.fontSize * fontSizeFactor,
      h1: {
        fontSize: calcCssSize(h1.fontSize, fontSizeFactor),
      },
      h2: {
        fontSize: calcCssSize(h2.fontSize, fontSizeFactor),
      },
      h3: {
        fontSize: calcCssSize(h3.fontSize, fontSizeFactor),
      },
      h4: {
        fontSize: calcCssSize(h4.fontSize, fontSizeFactor),
      },
      h5: {
        fontSize: calcCssSize(h5.fontSize, fontSizeFactor),
      },
      h6: {
        fontSize: calcCssSize(h6.fontSize, fontSizeFactor),
      },
      body1: {
        fontSize: calcCssSize(body1.fontSize, fontSizeFactor),
      },
      body2: {
        fontSize: calcCssSize(body2.fontSize, fontSizeFactor),
      },
      caption: {
        fontSize: calcCssSize(caption.fontSize, fontSizeFactor),
      },
      subtitle1: {
        fontSize: calcCssSize(subtitle1.fontSize, fontSizeFactor),
      },
    },
    shape: {
      borderRadius: typeof borderRadius === 'number' ? 20 * borderRadius : 4,
    },
    components: {
      ...components,
      MuiScopedCssBaseline: {
        styleOverrides: {
          root: {
            fontSize: typography.fontSize,
            backgroundColor: backgroundColor || 'inherit',
            a: {
              color: 'inherit',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          ...components?.MuiOutlinedInput?.styleOverrides,
          root: {
            borderRadius: calcBorderRadius(CUSTOM_INPUT_HEIGHT_FACTOR, borderRadius),
          },
          inputSizeSmall: {
            paddingTop: '0.53em',
            paddingBottom: '0.53em',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            '&[class]': {
              paddingTop: '0.37em',
              paddingBottom: '0.37em',
              '& .MuiAutocomplete-input': {
                paddingTop: '0.16em',
                paddingBottom: '0.16em',
              },
            },
          },
          endAdornment: {
            top: 'calc(50% - 0.75em)',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          ...components?.MuiButton?.styleOverrides,
          root: {
            fontSize: calcCssSize(typography.fontSize, fontSizeFactor),
            fontWeight: typography.fontWeightMedium,
            borderRadius: calcBorderRadius(CUSTOM_BUTTON_HEIGHT_FACTOR, borderRadius),
          },
          containedSizeMedium: {
            paddingTop: '0.41em',
            paddingBottom: '0.41em',
          },
          outlinedSizeMedium: {
            paddingTop: '0.41em',
            paddingBottom: '0.41em',
          },
          containedSizeLarge: {
            paddingTop: '0.45em',
            paddingBottom: '0.45em',
          },
          ...(fontColor && {
            containedPrimary: {
              color: fontColor,
            },
          }),
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: calcCssSize(typography.fontSize, fontSizeFactor * 1.714),
          },
          fontSizeSmall: {
            fontSize: calcCssSize(typography.fontSize, fontSizeFactor * 1.5),
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontSize: calcCssSize(typography.fontSize, fontSizeFactor * 0.875),
          },
          outlined: {
            fontWeight: 500,
            border: baseTheme.border,
          },
        },
      },
    },
  });

  return theme;
};
