import React from 'react';
import { MuiProvider, getThemeType, parseJson, splitProp } from '@guest-widgets/core';
import { classes } from '@guest-widgets/shared/src/classes';
import type { ConfigurationSettingsDto } from '@guest-widgets/shared/src/types/__mocks__/ConfigurationSettingsDto';
import type { CategoryPrefilterDto } from '@checkfront/guest-widgets-editor-api-api-client-javascript-axios';
import { APMInit } from '@guest-widgets/shared/src/components/ErrorBoundary';
import { uniqueCharId } from '@guest-widgets/shared/src/utils/uniqueCharId';
import { mapToLocation } from '@guest-widgets/shared/src/mappers/mapToLocation';
import { handleStringified } from '@guest-widgets/core/src/handleStringified';

import { App, AppProps } from './App/App';
import { mapToWidgetType } from './App/mappers/mapToWidgetType';
import { mapToFloating } from './App/mappers/mapToFloating';

export interface ButtonWidgetWebComponentProps
  extends Omit<
    AppProps,
    | 'configuration'
    | 'productId'
    | 'isFloating'
    | 'disableFeatures'
    | 'enableFeatures'
    | 'categoryFilter'
    | 'locationFilter'
  > {
  product?: string;
  floatingPosition?: string;
  configuration?: string | ConfigurationSettingsDto;
  disableFeatures?: string;
  enableFeatures?: string;
  categoryFilter?: string;
  locationFilter?: string;
}

APMInit();

export const ButtonWidgetWebComponent = ({
  product,
  configuration,
  widgetType,
  floatingPosition,
  disableFeatures,
  enableFeatures,
  categoryFilter,
  locationFilter,
  ...props
}: ButtonWidgetWebComponentProps) => (
  <MuiProvider classNamePrefix={uniqueCharId(classes.button.prefix)} themeType={getThemeType()}>
    <App
      {...props}
      widgetType={mapToWidgetType(widgetType)}
      floating={mapToFloating(floatingPosition)}
      productId={product}
      configuration={handleStringified(configuration, parseJson)}
      disableFeatures={splitProp(disableFeatures)}
      enableFeatures={splitProp(enableFeatures)}
      categoryPrefilter={mapToCategoryPrefilter(categoryFilter)}
      locationPrefilter={mapToLocation(locationFilter)}
    />
  </MuiProvider>
);

const mapToCategoryPrefilter = (categoryFilter?: string): CategoryPrefilterDto[] | undefined => {
  if (!categoryFilter) return undefined;
  return splitProp(categoryFilter).map(
    (id) => ({ id: Number(id) } as unknown as CategoryPrefilterDto)
  );
};
