import { useEffect } from 'react';
import { useWidget } from '@guest-widgets/core';

import { useQueryRouter } from '../components/Router/QueryRouter';
import { urlSearchParams } from '../utils/urlSearchParams';

import { useModalHandler } from './useModalHandler';

export const useModalActivator = () => {
  const { get: getLastStep } = useQueryRouter();
  const { instanceId } = useWidget();
  const searchParams = urlSearchParams();
  const queryParamInstanceId = searchParams.get('rwid');
  const isSuccessFailureStep = ['purchaseSuccess', 'purchaseFailure'].includes(
    getLastStep('rwstep') ?? ''
  );

  const [isOpen, setIsOpen] = useModalHandler(instanceId, isSuccessFailureStep);

  useEffect(() => {
    if (isSuccessFailureStep && queryParamInstanceId === String(instanceId)) {
      setIsOpen(true);
    }
  }, []);

  return { isOpen, onClose: () => setIsOpen(false), onOpen: () => setIsOpen(true) };
};
