const env = import.meta.env.VITE_ENV;

export const config = {
  apmVersion: import.meta.env.VITE_APM_VERSION,
  env,
  fallbackLanguage: 'en-US' as const,
  applicationIdForSpine: '0a0d7906-f1ec-4a1c-a1da-4d19b40a3c1b',
  productIdQueryKey: 're-product-id',
  filterQueryPrefix: 're-filter-',
  sharedResourcesBaseUrl: `https://shared-frontend-resources.${env}.regiondo.net`,
};

/** web-component widget tag names */
export const widgetTagNames = {
  button: 'button-widget',
  booking: 'booking-widget',
  catalog: 'product-catalog-widget',
  product: 'product-details-widget',
  productPreview: 'product-preview-widget',
  calendar: 'calendar-widget',
};
