import React, { ElementType } from 'react';
import type { CreateCSSProperties } from '@mui/styles';
import {
  Theme as DefaultTheme,
  StyledComponentProps,
  styled as muistyled,
} from '@mui/material/styles';
import { DistributiveOmit } from '@mui/types';

import { ContainersArea, useWidget } from '../widgetContext';

export type ComponentCreator<Component extends React.ElementType> = <
  Theme = DefaultTheme,
  Props extends {} = {}
>(
  styles:
    | CreateCSSProperties<Props>
    | ((props: { theme: Theme } & Props & ContainersArea) => CreateCSSProperties<Props>)
) => React.ComponentType<
  DistributiveOmit<
    JSX.LibraryManagedAttributes<Component, React.ComponentProps<Component>>,
    'classes' | 'className'
  > &
    StyledComponentProps<'root'> & { className?: string } & (Props extends { theme: Theme }
      ? DistributiveOmit<Props, 'theme'> & { theme?: Theme }
      : Props)
>;

export function styled<Component extends ElementType>(
  Component: Component
): ComponentCreator<Component> {
  const componentCreator = muistyled(
    React.forwardRef(
      ({ widgetArea, viewportArea, proportionalWidgetArea, ...rest }: ContainersArea, ref) => (
        /* @ts-ignore:disable-next-line */
        <Component ref={ref} {...rest} />
      )
    )
  );

  // @ts-ignore
  return (style) => {
    // @ts-ignore
    const Comp = componentCreator(style);

    return React.forwardRef((props, ref) => {
      const { widgetArea, viewportArea, proportionalWidgetArea } = useWidget();
      return (
        // @ts-ignore
        <Comp
          ref={ref}
          {...props}
          widgetArea={widgetArea}
          viewportArea={viewportArea}
          proportionalWidgetArea={proportionalWidgetArea}
        />
      );
    });
  };
}
